import PropTypes from "prop-types";
import React from "react";
import { StaticQuery, graphql } from "gatsby";

const BlogsContainer = ({ children }) => (
  <StaticQuery
    query={graphql`
      query {
        allFile {
          edges {
            node {
              id
              name
              publicURL
            }
          }
        }
        allBlogsPageJson {
          edges {
            node {
              id
              pageTitle
              hideBlogs
              blogDefaultBannerImage
              heroBannerSection {
                bannerImage
                heading
                content
                actionButton {
                  buttonLabel
                  buttonPath
                }
              }
            }
          }
        }
        allBlogsJson(
          filter: {
            title: { ne: "Default card(Do not delete and keep it hidden)" }
          }
          sort: { fields: date, order: DESC }
        ) {
          edges {
            node {
              id
              hideCard
              date(formatString: "MMMM DD YYYY")
              title
              bannerImage
              brief
              blogType
              externalLink
              document
              handWrittenBlog
            }
          }
        }
      }
    `}
    render={({
      allBlogsPageJson: { edges: blogsPageData },
      allBlogsJson: { edges: blogsData },
      allFile: { edges: filesData },
    }) =>
      children(
        blogsPageData.map((e) => ({
          ...e.node,
        })),
        blogsData.map((e) => ({
          ...e.node,
        })),
        filesData.map((e) => ({
          ...e.node,
        }))
      )
    }
  />
);

BlogsContainer.propTypes = {
  children: PropTypes.func.isRequired,
};

export default BlogsContainer;
