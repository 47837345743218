import React from "react";
import Box from "../../base/Box";
import SEO from "../SEO/seo";
import Section from "../Section";
import BlogsContainer from "../../containers/BlogsContainer";
import PageHeroBanner from "../PageHeroBanner";
import { titleToPath } from "../../helper/helper";
import BlogCard from "../CommonComponents/BlogCard";

const BlogsPage = () => (
  <BlogsContainer>
    {(blogsPageData, blogsData, filesData) => {
      const pageId = "blogs";
      const blogsInfo = blogsPageData.find((data) => data.id === pageId);
      const { heroBannerSection, hideBlogs, blogDefaultBannerImage } =
        blogsInfo;
      const filesInfo = [];
      filesData.map((file) => filesInfo.push(file.publicURL));
      return (
        <>
          <SEO pathname="blog" templateTitle={blogsInfo.pageTitle} />
          {heroBannerSection && (
            <PageHeroBanner
              actionButton={heroBannerSection.actionButton}
              bannerImage={heroBannerSection.bannerImage}
              content={{
                heading: heroBannerSection.heading,
                text: heroBannerSection.content,
              }}
            />
          )}
          {!hideBlogs && (
            <Section>
              <Box
                sx={{
                  mb: [2, null, null, 5],
                  textAlign: ["center", null, null, "left"],
                }}
                variant="markdown.h2"
              >
                Blogs
              </Box>
              <Box
                sx={{
                  display: "flex",
                  flexWrap: "wrap",
                  mx: -4,
                }}
              >
                {blogsData.map((blog) => {
                  const documentPublicURL = filesInfo.find(
                    (publicURL) =>
                      blog.document &&
                      publicURL.includes(
                        blog.document.split("/").filter((e) => e !== "")[1]
                      ) &&
                      publicURL
                  );
                  const blogDate = new Date(blog.date).getTime();
                  const currentDate = new Date().getTime();
                  return (
                    !blog.hideCard &&
                    currentDate >= blogDate && (
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                          flexWrap: "wrap",
                          px: 4,
                          py: [3, null, null, 4],
                          width: [
                            "100%",
                            null,
                            "calc(100% / 2)",
                            "calc(100% / 3)",
                          ],
                        }}
                      >
                        <BlogCard
                          bannerImage={
                            blog.bannerImage || blogDefaultBannerImage
                          }
                          cardContent={blog.brief}
                          cardDate={blog.date}
                          cardTitle={blog.title}
                          externalLink={
                            blog.blogType === "externalLink"
                              ? blog.externalLink
                              : blog.blogType === "document" &&
                                documentPublicURL
                          }
                          url={
                            blog.blogType === "handWrittenBlog" &&
                            `/blog/${titleToPath(blog.title)}`
                          }
                        />
                      </Box>
                    )
                  );
                })}
              </Box>
            </Section>
          )}
        </>
      );
    }}
  </BlogsContainer>
);

BlogsPage.propTypes = {};

export default BlogsPage;
