import React from "react";
import { Router } from "@reach/router";
import NotFoundPage from "./404";
import BlogsPage from "../components/BlogsPage";

const blogsPageRouter = (props) => (
  <Router>
    <BlogsPage {...props} path="/blog/" />
    <NotFoundPage default />
  </Router>
);

export default blogsPageRouter;
