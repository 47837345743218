import React from "react";
import PropTypes from "prop-types";
import Box from "../../base/Box";
import Image from "../Image";
import LinkTo from "../LinkTo";
import AspectRatio from "../AspectRatio";

const BlogCard = ({
  bannerImage,
  cardDate,
  cardContent,
  cardTitle,
  externalLink,
  sx,
  url,
}) => (
  <LinkTo
    hoverSx={{
      boxShadow: 6,
    }}
    isLink={externalLink}
    sx={{
      boxShadow: 5,
      color: "text.para",
      display: "flex",
      flex: "auto",
      flexDirection: "column",
      textAlign: "left",
      transition: ".15s",
      ...sx,
    }}
    target="_blank"
    to={url}
  >
    <AspectRatio ratio={[16, 9]}>
      {bannerImage && (
        <Image
          img={bannerImage}
          sx={{
            height: "100% !important",
            objectFit: "cover",
            width: "100% !important",
          }}
        />
      )}
    </AspectRatio>
    <Box sx={{ p: 4 }}>
      <Box
        as="h3"
        sx={{
          fontSize: 2,
          fontWeight: "bold",
          pb: 2,
        }}
      >
        {cardTitle}
      </Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "left",
          opacity: "75%",
          pb: 4,
        }}
      >
        <p>{cardDate}</p>
      </Box>
      <p>{cardContent.substring(0, 150)}...</p>
      <Box
        sx={{
          alignItems: "center",
          color: "yellowDark",
          display: "flex",
          fontWeight: "bold",
          justifyContent: "right",
          pt: 4,
        }}
      >
        <span>Read More </span>
        <Image
          svgIcon="icon-arrow"
          sx={{
            height: "3rem",
            ml: 2,
            width: "3rem",
          }}
        />
      </Box>
    </Box>
  </LinkTo>
);
BlogCard.propTypes = {
  bannerImage: PropTypes.string,
  cardContent: PropTypes.string,
  cardDate: PropTypes.string,
  cardTitle: PropTypes.string,
  childrenSx: PropTypes.shape({}),
  externalLink: PropTypes.string,
  hoverSx: PropTypes.shape({}),
  sx: PropTypes.shape({}),
  url: PropTypes.string,
};

BlogCard.defaultProps = {
  bannerImage: "",
  cardContent: "",
  cardDate: "",
  cardTitle: "",
  childrenSx: {},
  externalLink: "",
  hoverSx: {},
  sx: {},
  url: "",
};
export default BlogCard;
